import { apiV3 } from '@/api'

export type AverageMetricsResponse = {
  avg: {
    backend_response_time: number
    total_scanning_time: number
    zone_to_beep: number
  }
}

export type ScanType = 'enroll' | 'identify'
export type ScanStatus = 'failed' | 'success'

export type Scan = {
  type: ScanType
  date: string
  status: ScanStatus
  user_id: string
  device_name: string
  deviceapi_version: string
}

export type ScanMetricsResponse = {
  count: {
    count: number
  }
  detail: Scan[]
}

export default {
  user: {
    getAverageMetrics() {
      return apiV3.get<AverageMetricsResponse>('metrics/hasura/user-metrics/average-metrics/')
    },
    getScanMetrics() {
      return apiV3.get<ScanMetricsResponse>('metrics/hasura/user-metrics/scan-metrics/')
    },
  },
  organization: {
    getAverageMetrics(orgId: number) {
      return apiV3.get<AverageMetricsResponse>(
        `metrics/hasura/organization/${orgId}/average-metrics/`,
      )
    },
    getScanMetrics(orgId: number) {
      return apiV3.get<ScanMetricsResponse>(`metrics/hasura/organization/${orgId}/scan-metrics/`)
    },
  },
}
