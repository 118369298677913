<script lang="ts">
import { updateMembership } from '@/api/organization.js'
import { Btn } from '@keyo/ui'
import SelectField from '@/components/inputs/SelectField/SelectField.vue'
import toast from '@/libs/toast'

import { useCurrentRouteEntities, useModal } from '@/composables'
import type { PropType } from 'vue'
import { computed, reactive } from 'vue'
import { mapActions } from 'pinia'
import { useUsersStore } from '@/store/users'
import { required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import type { UserRole } from '@/types/user.ts'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default {
  name: 'ChangeRole',
  components: { Btn, SelectField },
  props: {
    membershipId: Number,
    userRole: {
      type: String as PropType<UserRole>,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const form = reactive({ organization_role: '' })
    type Errors = Partial<typeof form & { non_field_errors: string }>
    const externalResults = reactive<Errors>({})
    const rules = { organization_role: [required()] }
    const v$ = useVuelidate(rules, form, { $externalResults: externalResults })
    const route = useRoute()

    const modal = useModal()
    const { handleResponseException } = useFormHelpers()

    const { organization } = useCurrentRouteEntities()
    const rolesOptions = computed(() => {
      const options: { originalTitle: string; title: string; value: string }[] = []
      organization?.value?.roles.forEach((r: { name: string; id: string }) => {
        if (r.name === 'Owner' && props.userRole !== 'Owner') return
        options.push({
          originalTitle: r.name,
          title: t(r.name),
          value: r.id,
        })
      })
      return options
    })

    const roleId = rolesOptions.value.filter(r => r.originalTitle === props.userRole)[0]
    form.organization_role = roleId.value

    return {
      form,
      v$,
      externalResults,
      modal,
      route,
      rolesOptions,
      handleResponseException,
      toast: toast.show,
    }
  },
  data() {
    return {
      isSubmitting: false,
    }
  },
  computed: {
    errorMessage() {
      return this.v$.organization_role.$errors?.[0]?.$message as string
    },
  },
  methods: {
    ...mapActions(useUsersStore, ['fetchList']),
    async submitForm() {
      if (this.isSubmitting) return
      this.isSubmitting = true

      await this.v$.$validate()
      if (this.v$.$error) {
        this.isSubmitting = false
        return
      }
      try {
        await updateMembership(
          this.route.params.id as string,
          this.membershipId as number,
          this.form,
        )

        this.toast(() => this.$t('components.modals.changeRole.roleChanged'), 'success')
        await this.fetchList(this.route.params.id)
        this.cancel()
      } catch (e) {
        const { response } = e as AxiosError
        this.handleResponseException(response, this.externalResults)
        this.isSubmitting = false
      }
    },
    cancel() {
      this.modal.hide()
    },
  },
}
</script>

<template>
  <div class="invite__container">
    <h1 class="invite__title">{{ $t('common.changeRole') }}</h1>
    <div class="invite__form">
      <p class="invite__text">{{ $t('common.selectUserRole') }}</p>
      <SelectField
        v-model="form.organization_role"
        :options="rolesOptions"
        :state="v$.organization_role.$error ? 'error' : ''"
        :tip="errorMessage"
        :label="$t('common.organizationRole')"
      />
    </div>

    <div class="invite__buttons">
      <Btn
        type="submit"
        :is-disabled="v$.$invalid"
        :is-processing="isSubmitting"
        @click="submitForm"
      >
        {{ $t('buttons.save') }}
      </Btn>
      <Btn kind="minimal" @click="cancel">{{ $t('buttons.cancel') }}</Btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.invite__container {
  width: 100%;
  max-width: 500px;
}

.invite__title {
  font-weight: bold;
  font-size: 36px;
  margin: 0 0 20px 0;
}

.invite__form {
  max-width: 410px;
}

.invite__text {
  font-size: 16px;
  margin: 0 0 18px 0;
}

.invite__buttons {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 11px;
}
</style>
