<script lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'

import { Btn } from '@keyo/ui'

import useModal from '@/composables/useModal'

import toast from '@/libs/toast'

import { mapActions } from 'pinia'
import { useUsersStore } from '@/store'
import type { User } from '@/types/user.ts'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'MembersPause',
  components: { Btn },
  props: { members: { type: Array as PropType<User[]>, required: true } },
  setup() {
    const modal = useModal()
    const route = useRoute()
    const router = useRouter()
    return { modal, router, route, isProcessing: ref(false) }
  },
  computed: {
    names() {
      return this.members.map((u: User) => u.full_name || u.email).join(', ')
    },
  },
  methods: {
    ...mapActions(useUsersStore, ['pauseMembership']),
    async confirm() {
      try {
        this.isProcessing = true

        await Promise.all(this.members.map((u: User) => this.handlePauseMembership(u)))
        this.modal.hide()
      } catch (error) {
        console.error(error)
      } finally {
        this.isProcessing = false
      }
    },
    async handlePauseMembership(member: User) {
      try {
        await this.pauseMembership(this.route.params.id, member.id)
        toast.show(
          () => this.$t('components.modals.membersPause.pauseSuccess', { name: member.full_name }),
          'success',
        )

        this.route.params.memberId &&
          this.router.replace(`/organizations/${this.route.params.id}/members`)
      } catch (e) {
        console.error(e)
        toast.show(
          () => this.$t('components.modals.membersPause.pauseError', { name: member.full_name }),
          'error',
        )
      }
    },
  },
}
</script>

<template>
  <div class="action__container">
    <div class="action__img--wrapper">
      <div class="action__img--inner">
        <img src="@/assets/images/admin/pause-user.svg" alt="pause" class="action__img" />
      </div>
    </div>
    <div class="action__info">
      <h1 class="action__title">{{ $t('components.modals.membersPause.title') }}</h1>
      <p class="action__text">
        {{ $t('components.modals.membersPause.areYouSure') }} <strong>{{ names }}</strong
        >?
        {{ $t('components.modals.membersPause.confirmText') }}
      </p>
    </div>
    <div class="action__buttons">
      <Btn :is-processing="isProcessing" @click="confirm">{{ $t('buttons.confirm') }}</Btn>
      <Btn kind="minimal" @click="modal.hide()">{{ $t('buttons.cancel') }}</Btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action__container {
  width: 100%;
  max-width: 630px;
}
.action__img--wrapper {
  display: grid;
  place-items: center;
  width: 106px;
  height: 106px;
  background: rgba(249, 249, 249, 0.6);
  border-radius: 6px;
  box-shadow: 0 25px 70px rgba(0, 0, 0, 0.1);
}
.action__img--inner {
  display: grid;
  place-items: center;
  width: 90px;
  height: 90px;
  border-radius: 6px;
  background: #f9f9f9;
  box-shadow: 0 25px 70px rgba(0, 0, 0, 0.1);
}
.action__info {
  margin: 40px 0 75px 0;
  width: 100%;
  max-width: 410px;
}
.action__title {
  font-weight: 700;
  font-size: 36px;

  margin: 0 0 6px 0;
}
.action__text {
  font-size: 16px;
  margin: 0;
}
.action__buttons {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 11px;
}
</style>
