<script setup lang="ts">
import { Badge } from '@keyo/ui'
import { computed } from 'vue'
import type { UserRole } from '@/types/user.ts'
interface Props {
  role: UserRole
}
const props = defineProps<Props>()
const roleColors = {
  Owner: 'meadow',
  Admin: 'lavender',
  User: 'grey',
} as const

const color = computed(() => roleColors[props.role])
</script>

<template>
  <Badge :color="color">{{ $t(role) }}</Badge>
</template>
